@tailwind base;
@tailwind components;
@tailwind utilities;

  .theme-dark-purple {
    --background: 243 38% 18%;
    --foreground: 0 0% 100%;
    --button: 171 98% 49%;
    --button-text: 0 0% 0%;
    --button-hover: 171 99% 42%;
    --arrows: 243 38% 18%;

    --bright-yellow: 56 94% 58%;
    --vivid-orange: 41 98% 52%;
    --cyan: 171 98% 49%;

    --radius: 0.5rem;
  }

  .theme-light-purple {
    --background: 265 100% 75%;
    --foreground: 243 38% 18%;
    --button: 243 38% 18%;
    --button-text: 0 0% 100%;
    --arrows: 243 38% 18%;

    --bright-yellow: 56 94% 58%;
    --vivid-orange: 41 98% 52%;
    --cyan: 171 98% 49%;
    
    --radius: 0.5rem;
  }

  .theme-black {
    --background: 0 0% 0%;
    --foreground: 240 4.8% 95.9%;
    --button: 0 0% 100%;
    --button-text: 0 0% 0%;
    --button-hover: 0 0% 90%;
    --arrows: 243 38% 18%;

    --bright-yellow: 56 94% 58%;
    --vivid-orange: 41 98% 52%;
    --cyan: 171 98% 49%;
    
    --radius: 0.5rem;
  }

  .theme-dark-lightblue {
    --background: 0 0% 0%;
    --foreground: 240 4.8% 95.9%;
    --button: 171 98% 49%;
    --button-text: 0 0% 0%;
    --button-hover: 171 99% 42%;
    --arrows: 243 38% 18%;

    --bright-yellow: 56 94% 58%;
    --vivid-orange: 41 98% 52%;
    --cyan: 171 98% 49%;
    
    --radius: 0.5rem;
  }

  .theme-lizcoin {
    --background: 0 0% 0%;
    --foreground: 240 4.8% 95.9%;
    --button: 243 38% 18%;
    --button-text: 0 0% 100%;
    --button-hover: 243 38% 15%;
    --arrows: 243 38% 18%;

    --bright-yellow: 56 94% 58%;
    --vivid-orange: 41 98% 52%;
    --cyan: 171 98% 49%;
    
    --radius: 0.5rem;
  }

  .theme-dark-blue {
    --background: 125 31% 7%;
    --foreground: 240 4.8% 95.9%;
    --button: 171 98% 49%;
    --button-hover: 171 99% 42%;
    --arrows: 243 38% 18%;

    --bright-yellow: 56 94% 58%;
    --vivid-orange: 41 98% 52%;
    --cyan: 171 98% 49%;
    
    --radius: 0.5rem;
  }

  .theme-green {
    --background: 137 87% 56%;
    --foreground: 266 39% 17%;
    --button: 243 38% 18%;
    --button-text: 0 0% 100%;
    --button-hover: 243 38% 25%;
    --arrows: 243 38% 18%;

    --bright-yellow: 56 94% 58%;
    --vivid-orange: 41 98% 52%;
    --cyan: 171 98% 49%;
    
    --radius: 0.5rem;
  }

  .theme-cyan {
    --background: 171 98% 49%;
    --foreground: 246 48% 17%;
    --button: 243 38% 18%;
    --button-text: 0 0% 100%;
    --button-hover: 243 38% 25%;
    --arrows: 243 38% 18%;

    --bright-yellow: 56 94% 58%;
    --vivid-orange: 41 98% 52%;
    --cyan: 171 98% 49%;
    
    --radius: 0.5rem;
  }

  .theme-yellow {
    --background: 41 98% 52%;
    --foreground: 246 48% 17%;
    --button: 243 38% 18%;
    --button-text: 0 0% 100%;
    --button-hover: 243 38% 25%;
    --arrows: 243 38% 18%;

    --bright-yellow: 56 94% 58%;
    --vivid-orange: 41 98% 52%;
    --cyan: 171 98% 49%;
    
    --radius: 0.5rem;
  }

  .theme-invest {
    --background: 0 0% 0%;
    --foreground: 0 0% 100%;
    --button: 0 0% 100%;
    --button-text: 0 0% 0%;
    --button-hover: 0 0% 90%;
    --arrows: 243 38% 18%;

    --cyan: 171 72% 45%;
    --primary: 171 100% 36%;
    --investor-green: 171 100% 36%;
    --radius: 0.5rem;
  }

@layer base {
  * {
    @apply border-border; 
  }
  body {
    @apply bg-background text-foreground;
    -webkit-tap-highlight-color: transparent;
  }
}

@font-face {
  font-family: "Figtree";
  src: url("./assets/fonts/Figtree-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Figtree";
  src: url("./assets/fonts/Figtree-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "NekstBold";
  src: url("./assets/fonts/NekstBold.otf");
}

@font-face {
  font-family: "NekstSemiBold";
  src: url("./assets/fonts/NekstSemiBold.otf");
}

@font-face {
  font-family: "Khand";
  src: url("./assets/fonts/Khand-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

#home_sections {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.home-page section {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  perspective: 500px;
}

.dark-logo, .theme-cyan .logo, .theme-light-purple .logo, .theme-green .logo, .theme-yellow .logo {
  display: none;
}

.logo, .theme-cyan .dark-logo, .theme-light-purple .dark-logo, .theme-green .dark-logo, .theme-yellow .dark-logo  {
  display: flex;
}

.foreground-border {
  border: 1px solid rgba(255, 255, 255, .5);
}

.theme-cyan .foreground-border, .theme-light-purple .foreground-border, .theme-green .foreground-border, .theme-yellow .foreground-border {
  border: 1px solid rgba(0, 0, 0, .5);
}

.color-dot {
  display: flex;
}
.black-dot {
  display: none;
}

.theme-cyan .color-dot, .theme-green .color-dot, .theme-yellow .color-dot {
  display: none;
}

.theme-cyan .black-dot, .theme-green .black-dot, .theme-yellow .black-dot {
  display: flex;
}

.bg-cta, .bg-cta-active, .bg-cta-black {
  position: relative;
  overflow: hidden;
}

.bg-cta:before, .bg-cta-active:before, .bg-cta-black:before {
  content: '';
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  height: 100%;
  width: 20px;
  left: -20px;
  transform: skewX(-20deg);
}

.bg-cta:hover:before, .bg-cta-active::before {
  animation: slide 0.5s;
}

@keyframes scroll {
  0% { transform: translateY(0); }
  100% { transform: translateY(-100%); }
}

@keyframes slide {
  0% { 
    left: -20px;
  }
  100% {
    left: 100%
  }
}

@keyframes slide-back {
  0% { 
    left: 100%;
  }
  100% {
    left: -20px;
  }
}

.bounce {
  animation: bounce 2s ease;
  animation-iteration-count: infinite;
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

.bg-team {
  background-image: linear-gradient(rgba(2, 247,  211, 0) 20%, rgba(2, 247,  211, 1));
}

.h-dynamic {
  min-height: 100%;
  height: 100vh; /* fallback for Opera, IE and etc. */
  height: 100dvh;
}

.inverted-border-radius::before {
  content: "";
  position: absolute;
  
  background-color: transparent;
  bottom: -2rem;
  right: 1rem;
  height: 2rem;
  width: 1rem;
  border-top-right-radius: 5em;
  border-bottom-right-radius: 1em;
  box-shadow: 0 -1rem 0 0 #1f1e40;
}

.inverted-border-radius-left::before {
  content: "";
  position: absolute;
  
  background-color: transparent;
  bottom: -2rem;
  left: -2rem;
  height: 2rem;
  width: 1rem;
  border-top-right-radius: 5em;
  border-bottom-right-radius: 1em;
  box-shadow: 0 -1rem 0 0 #1f1e40;
}